import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { useFavorites } from "../context/FavoritesContext";

const Header = () => {
  const [isNavVisible, setIsNavVisible] = useState(false);
  const { favorites } = useFavorites(); // Utilisez le hook useFavorites pour accéder aux favoris

  const toggleNav = () => {
    setIsNavVisible(!isNavVisible);
    if (!isNavVisible) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  };

  // Fonction pour fermer le menu
  const closeNav = () => {
    setIsNavVisible(false);
    document.body.classList.remove("no-scroll");
  };

  return (
    <header className="header">
      <Link to="/">
        <img alt="logo" src="/assets/logo.svg" className="header__logo" />
      </Link>
      <div
        className={`nav-burger ${isNavVisible ? "open" : ""}`}
        onClick={toggleNav}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
      <nav className={`header__nav ${isNavVisible ? "open" : ""}`}>
        <NavLink
          to="/"
          className={({ isActive }) => (isActive ? "activeLink" : "")}
          onClick={closeNav}
        >
          Home
        </NavLink>
        <NavLink
          to="/products"
          className={({ isActive }) => (isActive ? "activeLink" : "")}
          onClick={closeNav}
        >
          Products
        </NavLink>
        <NavLink
          to="/outfit-idea"
          className={({ isActive }) => (isActive ? "activeLink" : "")}
          onClick={closeNav}
        >
          Outfit Idea
        </NavLink>
        <NavLink
          to="/order"
          className={({ isActive }) => (isActive ? "activeLink" : "")}
          onClick={closeNav}
        >
          How to Order
        </NavLink>
        <NavLink
          to="/link-converter"
          className={({ isActive }) => (isActive ? "activeLink" : "")}
          onClick={closeNav}
        >
          Link Converter
        </NavLink>
        <NavLink
          to="/favorites"
          className={({ isActive }) => (isActive ? "activeLink" : "")}
          onClick={closeNav}
        >
          {favorites.length > 0 && (
            <span className="header__nav__favorite">{favorites.length}</span>
          )}{" "}
          {/* Affiche le nombre de favoris seulement s'il est > 0 */}
          <img alt="favorite button" src="/assets/favorite.svg" />
        </NavLink>
      </nav>
    </header>
  );
};

export default Header;
