import React from "react";

export const Link = () => {
  return (
    <div className="container">
      <h1 className="banner__title">Coming soon</h1>
    </div>
  );
};
export default Link;
