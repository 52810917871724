import React from "react";
import { useState } from "react";
import { useFavorites } from "../context/FavoritesContext";
import "../styles/Product.scss";
import { convertCNYtoUSD } from "../utils/currencyConversion";
import { generateAllChinaBuyLink } from "../utils/generateAllChinaBuyLink";
import { generateSuperBuyLink } from "../utils/generateSuperBuy";
import { generateMuleBuyLink } from "../utils/generateMuleBuy";
import { generateHooBuyLink } from "../utils/generateHooBuy";
import { generateCNFansLink } from "../utils/generateCnFans";

const FavoritesPage = () => {
  const { favorites, removeFavorite } = useFavorites();
  const [hoveredProductId, setHoveredProductId] = useState(null);

  return (
    <div className="container">
      <div className="product">
        <h2 className="banner__title">My Favorites</h2>
        {favorites.length > 0 ? (
          <ul className="product__list">
            {favorites.map((product) => (
              <li
                className="product__card"
                key={product.id}
                onMouseEnter={() => setHoveredProductId(product.id)}
                onMouseLeave={() => setHoveredProductId(null)}
              >
                {product.image && (
                  <a href={product.link}>
                    <img
                      className="product__image"
                      src={product.image}
                      alt={product.name}
                    />
                  </a>
                )}
                {hoveredProductId === product.id && (
                  <div className="product__overlay">
                    <a
                      href={generateAllChinaBuyLink(product.link)}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="product__link product__link--acb"
                    >
                      AllChinaBuy
                    </a>
                    <a
                      href={generateSuperBuyLink(product.link)}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="product__link product__link--superbuy"
                    >
                      SuperBuy
                    </a>
                    <a
                      href={generateHooBuyLink(product.link)}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="product__link product__link--hoobuy"
                    >
                      HooBuy
                    </a>
                    <a
                      href={generateMuleBuyLink(product.link)}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="product__link product__link--mulebuy"
                    >
                      MuleBuy
                    </a>
                    <a
                      href={generateCNFansLink(product.link)}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="product__link product__link--cnfans"
                    >
                      CnFans
                    </a>
                  </div>
                )}
                <div className="product__info">
                  <p className="product__name">{product.name}</p>
                  <p className="product__price">{`${convertCNYtoUSD(product.price)} $`}</p>
                  <p className="product__colorway">
                    {product.colorway ? `(${product.colorway})` : ""}
                  </p>
                  <button
                    onClick={() => removeFavorite(product.id)}
                    className="product__button"
                  >
                    Remove from Favorites
                  </button>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p>No products in your favorites.</p>
        )}
      </div>
    </div>
  );
};

export default FavoritesPage;
